import { Client } from 'typesense'
import { SearchResponseFacetCountSchema } from 'typesense/lib/Typesense/Documents'
import config from 'config'
import { MultiSearchRequestSchema } from 'typesense/lib/Typesense/MultiSearch'

let client = new Client({
  nodes: [
    {
      host: config.modules.typesense.host,
      port: config.modules.typesense.port,
      protocol: config.modules.typesense.protocol,
    },
  ],
  apiKey: config.modules.typesense.apiKey,
  connectionTimeoutSeconds: config.modules.typesense.connectionTimeoutSeconds,
})

export type HelperOptions = {
  disjunctiveFacets?: string[]
  hitsPerPage?: number
  hierarchicalFacets?: {
    name: string
    fields: string[]
  }[]
  maxValuesPerFacet?: number
  attributesToRetrieve?: string[]
  facetQuery?: string
}

export type SearchResult<Hit> = {
  hits: Hit[]
  totalHits: number
  numPages: number
  page: number
  facets: Record<string, SearchResponseFacetCountSchema<any>>
  hierarchicalFacets: Record<string, HierarchicalTreeItem[]>
}

export type HierarchicalTreeItem = {
  label: string
  count: number
  path: string
  selected: boolean
  children: null | HierarchicalTreeItem[]
}

export function createSearchHelper<T = unknown>(collection: string, opt: HelperOptions = {}) {
  let options = {
    q: '',
    tags: [] as string[],
    disjunctiveFacets: {} as Record<string, string[]>,
    categoryFacets: [] as string[],
    page: 1,
    numericFacets: {} as Record<string, string>,
    hierarchicalFacets: {} as Record<string,string>,
    sorting: config.sortingAttributes.default,
  }

  return {
    setQuery(q: string) {
      options.q = q
    },
    addDisjunctiveFacet(key: string, value: string) {
      if (!options.disjunctiveFacets[key]) options.disjunctiveFacets[key] = []
      options.disjunctiveFacets[key].push(value)
    },
    addNumericFacet(key: string, operator: '>=' | '<=', value: string | number) {
      options.numericFacets[key] = `${operator}${value}`
    },
    addRangeFacet(key: string, from: string | number, to: string | number) {
      options.numericFacets[key] = `[${from}..${to}]`
    },
    setHierarchicalFacet(key:string, value:string) {
      options.hierarchicalFacets[key] = value
    },
    addTag(value: string) {
      options.tags.push(value)
    },
    setPage(page: number) {
      options.page = page + 1
    },
    setSorting(attribute: string) {
      options.sorting = attribute
    },
    async search(extOpt: HelperOptions = {}) {
      const helperOptions = Object.assign({}, opt, extOpt)
      let filter_by: string[] = []
      for (const name in options.disjunctiveFacets) {
        if (options.disjunctiveFacets[name].length === 0) continue
        filter_by.push(`${name}:=[${options.disjunctiveFacets[name].join(',')}]`)
      }
      if (options.tags.length) {
        filter_by.push(`_tags:=[${options.tags.join(',')}]`)
      }
      for (const name in options.numericFacets) {
        if (!options.numericFacets[name]) continue
        filter_by.push(`${name}:${options.numericFacets[name]}`)
      }
      if (opt.hierarchicalFacets) {
        for(const row of opt.hierarchicalFacets) {
          const value = options.hierarchicalFacets[row.name] || ''
          if(!value) continue
          let parts = value.split(' > ')
          for(let i=0;i<parts.length;i++) {
            const property = row.fields[i] 
            if(!property) continue
            filter_by.push(`${property}:=${parts.slice(0, i+1).join(' > ')}`)
          }
        }
      }
      const searchParameters: MultiSearchRequestSchema[] = [
        {
          q: options.q,
          collection: collection,
          query_by: 'productManufacturerBrand,merchantName,wunderCategories,productName,_tags,tagGroups.Absatzhöhen,tagGroups.Kuratierte Pages,tagGroups.Absatzart,tagGroups.Details,tagGroups.Stiltypen,tagGroups.Besondere Marken,tagGroups.Musterarten,tagGroups.Ärmellängen,tagGroups.Shapewear-Arten,tagGroups.Ausschnitt,tagGroups.BH-Typen,tagGroups.Materialien,tagGroups.Anlässe,tagGroups.Figurtypen,tagGroups.Passform,productShortDescription,productColor,filterColor,wunderSizes,objectID',
          infix: 'off,off,always,always,always,always,always,always,always,always,always,always,always,always,always,always,always,always,always,always,always,always,always,off,off', // enable infix searching only for wunderCategories,productName,productShortDescription,productColor,filterColor
          filter_by: filter_by.join('&&'),
          facet_by: (helperOptions.disjunctiveFacets || []).join(','),
          facet_query: helperOptions.facetQuery || '',
          sort_by: options.sorting,
          page: options.page,
          per_page: helperOptions.hitsPerPage,
          max_facet_values: helperOptions.maxValuesPerFacet,
          include_fields: (helperOptions.attributesToRetrieve || []).join(','),
          highlight_fields: '',
          highlight_full_fields: '',
        },
      ]

      for (const name in options.disjunctiveFacets) {
        if (options.disjunctiveFacets[name].length === 0) continue
        const params: MultiSearchRequestSchema = JSON.parse(JSON.stringify(searchParameters[0]))
        params.per_page = 1
        params.page = 1
        params.include_fields = ''
        params.filter_by = (params.filter_by || '')
          .split('&&')
          .filter((s) => !s.startsWith(name + ':'))
          .join('&&')
        searchParameters.push(params)
      }

      for (const name in options.numericFacets) {
        if (!options.numericFacets[name]) continue
        const params: MultiSearchRequestSchema = JSON.parse(JSON.stringify(searchParameters[0]))
        params.per_page = 1
        params.page = 1
        params.include_fields = ''
        params.filter_by = (params.filter_by || '')
          .split('&&')
          .filter((s) => !s.startsWith(name + ':'))
          .join('&&')
        searchParameters.push(params)
      }

      if(opt.hierarchicalFacets) {
        for(const row of opt.hierarchicalFacets) {
          const value = options.hierarchicalFacets[row.name] || ''
          const parts = value.split(' > ')
          if(parts[0] !== '') parts.unshift('')
          for(let i=0;i<parts.length;i++) {
            if(i >= row.fields.length) continue
            const params: MultiSearchRequestSchema = JSON.parse(JSON.stringify(searchParameters[0]))
            const property = row.fields[i]
            params.per_page = 1
            params.page = 1
            params.include_fields = ''
            params.facet_by = property

            let filter_by_list = (params.filter_by || '').split('&&')
            // console.log(filter_by_list)

            for(let k=i;k<row.fields.length;k++) {
              const property = row.fields[k]
              filter_by_list = filter_by_list.filter((s) => !s.startsWith(property + ':'))
            }

            params.filter_by = filter_by_list.join('&&')
            searchParameters.push(params)
          }
        }
      }

      const multiResult = await client.multiSearch.perform({ searches: searchParameters })

      const result = multiResult.results.shift() as typeof multiResult.results[0]

      for (const name in options.disjunctiveFacets) {
        if (options.disjunctiveFacets[name].length === 0) continue
        const fresult = multiResult.results.shift() as typeof multiResult.results[0]
        const fIndex = (fresult.facet_counts || []).findIndex((row) => row.field_name === name)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        result.facet_counts![fIndex] = fresult.facet_counts![fIndex]
      }

      for (const name in options.numericFacets) {
        if (!options.numericFacets[name]) continue
        const fresult = multiResult.results.shift() as typeof multiResult.results[0]
        const fIndex = (fresult.facet_counts || []).findIndex((row) => row.field_name === name)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        result.facet_counts![fIndex] = fresult.facet_counts![fIndex]
      }

      let hierarchicalResult:SearchResult<T>['hierarchicalFacets'] = {}
      if(opt.hierarchicalFacets) {
        for(const row of opt.hierarchicalFacets) {
          const tree:HierarchicalTreeItem[] = []
          let activeItem:HierarchicalTreeItem|null = null
          const value = options.hierarchicalFacets[row.name] || ''
          const parts = value.split(' > ')
          if(parts[0] !== '') parts.unshift('')
          for(let i=0;i<parts.length;i++) {
            const fresult = multiResult.results.shift() as typeof multiResult.results[0]
            if(i >= row.fields.length) {
              const label = parts[i]
              if(activeItem && activeItem.children) {
                const target = activeItem.children.find(item => item.label === label)
                if(target) target.selected = true
              }
              break
            }
            const fIndex = fresult.facet_counts ? fresult.facet_counts[0] : null
            let tempActiveItem:HierarchicalTreeItem|null = null

            if (fIndex) {
              for(const row of fIndex.counts) {
                const item = {
                  path: row.value,
                  count: row.count,
                  label: row.value.split(' > ').pop() || '',
                  children: null,
                  selected: false
                }
                if(activeItem) {
                  activeItem.children = activeItem.children || []
                  activeItem.children.push(item)
                }
                else {
                  tree.push(item)
                }
  
                // active tree-item
                if(value.includes(item.path)) {
                  tempActiveItem = item
                  item.selected = true
                }
              }
            }
            activeItem = tempActiveItem
          }
          hierarchicalResult[row.name] = tree
        }
      }

      const facets = {} as Record<string, SearchResponseFacetCountSchema<any>>
      for (const row of result.facet_counts || []) {
        facets[row.field_name] = row
      }

      return {
        hits: (result.hits || []).map((hit) => hit.document) as T[],
        totalHits: result.found,
        numPages: Math.ceil(result.found / (helperOptions.hitsPerPage || 10)),
        hierarchicalFacets: hierarchicalResult,
        page: result.page,
        facets,
      } as SearchResult<T>
    },
  }
}
